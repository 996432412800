angular.module('app.controllers').controller('ProfileCtrl', ['$scope', '$timeout', 'APICheckin', '$rootScope', 'UI'].append(function(self, $timeout, APICheckin, root, UI) {
  var loadProfile, procData;
  self.formProccess = false;
  self.formSuccess = false;
  self.resetFake = function() {
    return self.fake = {
      password: ''
    };
  };
  self.resetFake();
  self.passType = true;
  self.passType2 = true;
  self.togglePass = function(pass = true) {
    if (pass) {
      return self.passType = !self.passType;
    }
    return self.passType2 = !self.passType2;
  };
  loadProfile = function() {
    self.dataLoad = true;
    return APICheckin.get({
      auth_ticket: root.user.auth_ticket,
      _private: true
    }, function(data) {
      self.profile = data;
      if (self.profile.user.first_name === "Unknown") {
        self.profile.user.first_name = "";
      }
      if (self.profile.user.last_name === "Unknown") {
        self.profile.user.last_name = "";
      }
      self.dataLoad = false;
      return procData();
    });
  };
  if (self.at('profile')) {
    loadProfile();
  }
  self.$on('openOnboardModal', function(event) {
    loadProfile();
    return self.$applyAsync();
  });
  procData = function() {
    return self.profile.user.gender = `${self.profile.user.gender}`;
  };
  self.saveForm = function(form) {
    return self.pForm = form;
  };
  self.saveMForm = function(form, name = '') {
    return self[name] = form;
  };
  return self.saveProfile = function(onboard = false) {
    var ref, ref1, ref2;
    self.formProccess = true;
    if (onboard) {
      if ((ref = self.profile.user.settings) != null) {
        if ((ref1 = ref.additional_field) != null) {
          ref1.additional_field_value_3 = true;
        }
      }
    }
    return self.profile.$save({
      _private: true,
      auth_ticket: (ref2 = root.user) != null ? ref2.auth_ticket : void 0
    }, function(data) {
      var ref10, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
      self.formProccess = false;
      if ((ref3 = self.pForm) != null) {
        ref3.$setPristine();
      }
      if ((ref4 = self.pForm) != null) {
        ref4.$setUntouched();
      }
      if ((ref5 = self.pForm1) != null) {
        ref5.$setPristine();
      }
      if ((ref6 = self.pForm1) != null) {
        ref6.$setUntouched();
      }
      if ((ref7 = self.pForm2) != null) {
        ref7.$setPristine();
      }
      if ((ref8 = self.pForm2) != null) {
        ref8.$setUntouched();
      }
      if ((ref9 = self.pForm3) != null) {
        ref9.$setPristine();
      }
      if ((ref10 = self.pForm3) != null) {
        ref10.$setUntouched();
      }
      procData();
      console.log(self.fake.password);
      self.fake.password = '';
      root.session = data;
      if (onboard) {
        root.onModal.hide();
      }
      return UI.notify(true, 'Ihr Profil wurde erfolgreich gespeichert');
    }, function(errorData) {
      UI.notify(false, errorData.data.error);
      self.formProccess = false;
      return self.fake.password = '';
    });
  };
}));
