angular.module('app.controllers').controller 'ProfileCtrl', [
  '$scope', '$timeout', 'APICheckin', '$rootScope', 'UI'
].append (self, $timeout, APICheckin, root, UI) ->

  self.formProccess  = false
  self.formSuccess   = false

  self.resetFake = () ->
    self.fake = 
      password: ''
      
  self.resetFake()
  
  self.passType  = true
  self.passType2 = true
  self.togglePass = (pass = true) ->
    return self.passType = !self.passType if pass
    self.passType2 = !self.passType2


  loadProfile = () ->
    self.dataLoad = true
    APICheckin.get {auth_ticket: root.user.auth_ticket, _private: true}, (data) ->
      self.profile = data
      self.profile.user.first_name = "" if self.profile.user.first_name == "Unknown"
      self.profile.user.last_name = "" if self.profile.user.last_name == "Unknown"
      self.dataLoad = false
      procData()
  
  loadProfile() if self.at('profile')

  self.$on 'openOnboardModal', (event) ->
    loadProfile()
    self.$applyAsync()

  procData = () ->
    self.profile.user.gender = "#{self.profile.user.gender}"

  self.saveForm = (form) ->
    self.pForm = form
 
  self.saveMForm = (form, name = '') ->
    self[name] = form

  self.saveProfile = (onboard = false) ->
    self.formProccess = true

    if onboard
      self.profile.user.settings?.additional_field?.additional_field_value_3 = true      

    self.profile.$save {_private: true, auth_ticket: root.user?.auth_ticket}, (data) ->
      self.formProccess = false

      self.pForm?.$setPristine()
      self.pForm?.$setUntouched()

      self.pForm1?.$setPristine()
      self.pForm1?.$setUntouched()

      self.pForm2?.$setPristine()
      self.pForm2?.$setUntouched()

      self.pForm3?.$setPristine()
      self.pForm3?.$setUntouched()

      procData()   
      console.log self.fake.password
      self.fake.password = ''  
      root.session = data

      root.onModal.hide() if onboard

      UI.notify true, 'Ihr Profil wurde erfolgreich gespeichert'

    , (errorData) ->
      UI.notify false, errorData.data.error
      self.formProccess = false
      self.fake.password = '' 
